import React from 'react'

const ProductRange = (props) => {
  const className = `product-range ${props.productRange}`

  return (
    <div className={className}>
      <div className="__block">
        <div
          className="story"
          data-sal-duration="600"
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          {props.children}
        </div>
      </div>
      <div className="__hero">
        <img
          src={props.heroImage}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
      </div>
    </div>
  )
}

export default ProductRange